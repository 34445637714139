import React, { useRef, useState, useEffect } from 'react';
import './Experience.css';
import { motion, useScroll, useSpring } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaChevronDown } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';

import devcity from '../../assets/logos/devcity.svg'
import anyrent from '../../assets/logos/anyrent.svg'
import zse from '../../assets/logos/zse.png'
import nehnutelnosti from '../../assets/logos/nehnutelnostisk-logo-light.png'
import ey from '../../assets/logos/ey.png'
import dxb from '../../assets/logos/dxb.png'
import c13 from '../../assets/logos/c13.webp'
import cam from '../../assets/logos/cambridgeuni.png'
import agevolt from '../../assets/logos/agevolt.png'
import za from '../../assets/logos/zilina.jpg'
import bsk from '../../assets/logos/bsk.webp'
import accenture from '../../assets/logos/accenture.png'
import beth from '../../assets/logos/beth.png'
import mobilityxlab from '../../assets/portfolio/mobilityxlab.png'
import neu from '../../assets/logos/neu.svg'
import goodrequest from '../../assets/logos/goodrequest.jpeg'
import antekirt from '../../assets/logos/antekirt.png'

interface ExperienceItem {
    years: string;
    title: string;
    description: string;
    images: string[];
    link?: string;
}

const Experience: React.FC = () => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isGlowing, setIsGlowing] = useState(false);
    const { t } = useTranslation();

    const experienceData: ExperienceItem[] = [
        {
            "years": "2024 - Present",
            "title": "Co-founder, Fullstack Developer - Antekirt",
            "description": "Train AI models with your unique style, earn from every generation, and maintain full commercial rights to AI-generated artwork. MVP delivered in less than 40 hours.",
            "images": [antekirt],
            "link": "https://antekirt.com/"
        },
        {
            "years": "2023 - Present",
            "title": "Senior Backend Dev - GoodRequest",
            "description": "Leading the development team for largest EV charging solution provider in Slovakia. Participating on the development of AI assistant for legal documents.",
            "images": [goodrequest],
            "link": "https://goodrequest.com/"
        },
        {
            "years": "2023 - 2024",
            "title": "Devcity.tech - Fullstack Developer",
            "description": "Working on the largest online rental service for bikes, skis and sports equipment with delivery - Anyrent.cz. Participating on the development of the platform and the integration of the CMS.",
            "images": [devcity, anyrent],
            "link": "https://anyrent.cz/"
        },
        {
            "years": "2022 - 2023",
            "title": "Cohort 5 - Carbon13 - Cambridge University",
            "description": t('experience.data.cambridgeDescription'),
            "images": [c13, cam],
            "link": "https://carbonthirteen.com/"
        },
        {
            "years": "2022 - 2024",
            "title": "Blockchain Expert / Consultant - Ernst & Young",
            "description": "Responsible for a blockchain use-case research and assessment workshop for Dubai Airport, and in-person presentation of selected use-cases to Dubai Airport's VP's. This facilitated the closure of a lucrative deal between Ernst & Young and Dubai Airport.",
            "images": [ey, dxb],
            "link": "https://www.ey.com/cs_cz"
        },
        {
            "years": "2021 - 2022",
            "title": "Head of Digital Platform - AgeVolt",
            "description": "Ensuring research and development activities will maintain an organization's competitive position and profitability. R&D team management (building the team from 0 to 12 team members) Presentations for partners, clients, suppliers and investors. Adjusting and responsibility for the budget. Daily communication with team colleagues and solving their blockers. Focusing on implementation of blockchain technology into infrastructure and products of AgeVolt Digital Platform.",
            "images": [agevolt],
            "link": "https://www.agevolt.com/"
        },
        {
            "years": "2021 - 2021",
            "title": "mobilityXlab Finalist - AgeVolt representative",
            "description": "MobilityXlab is a collaboration hub within mobility offering startups a fast-track into a potential strategic partnership with industry leaders. I was representing the AgeVolt in front of strategic partners like Ericsson, Magna, Polestar, Volvo Group, Zeekr Technology Europe, and Zenseact. Researching and presenting the collaboration opportunities and join-ventures.",
            "images": [mobilityxlab],
            "link": "https://www.mobilityxlab.com/"
        },
        {
            "years": "2018 - 2022",
            "title": "Borad Member - Neuromorphics Europe",
            "description": "Neuromorphics Europe is focusing on the implementation of the latest advances in the field of Neuromorphic in real life. Periodic Evangelization Meet-ups, Review of Knowledge Base by Academic community, PoC Lab. Humanitarian a Cognitive Sciences design pattern synthesis – New Way of working development.",
            "images": [neu],
            "link": "https://neuromorphics.eu/"
        },
        {
            "years": "2020 - 2020",
            "title": "Adjunct Lecturer in Cryptocurrencies - University of Zilina",
            "description": "Teaching Cryptocurrency and Blockchain course for students of Informatics.",
            "images": [za],
            "link": "https://www.uniza.sk/index.php/en/"
        },
        {
            "years": "2017 - 2022",
            "title": "Head of Technology & Tech Consultant - Blockchain Slovakia NGO",
            "description": "Providing consultation and direct participation in the creation of cryptocurrency regulation legislation with Slovak government representatives. Speaker at various blockchain/crypto conferences.",
            "images": [bsk],
            "link": "https://blockchainslovakia.sk/"
        },
        {
            "years": "2019 - 2021",
            "title": "Application Development Senior Analyst - Accenture",
            "description": "Partially focusing on experimental blockchain projects supporting the Accenture innovation position on the market and offering the existing clients new innovative approaches and solutions and partially part of a NATO security project in secure information-restricted mode.",
            "images": [accenture],
            "link": "https://www.accenture.com/sk-en"
        },
        {
            "years": "2017 - 2019",
            "title": "Lead Blockchain & Smart Contracts Dev - Bethereum",
            "description": "Responsible for the organization and execution of token sale fundraising and the development of Ethereum smart contracts used as a core backend of the Bethereum. Smart contracts represented the betting logic in decentralized peer-to-peer betting solution.",
            "images": [beth],
            "link": "https://coinmarketcap.com/currencies/bethereum/"
        },
        {
            "years": "2014 - 2017",
            "title": "Freelance Web Dev",
            "description": "Working as a freelance developer for various clients. Most of the projects been short-term gigs like creating web pages, ecommerce solutions etc.",
            "images": [],
        }
    ];

    return (
        <section id="experience" className="experience-section">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">
                    {t('experience.title')}<span className="text-red-500">.</span>
                </h2>
                <p className="text-xl text-gray-400 text-center mb-16">
                    {t('experience.subtitle')}
                </p>

                <div className="timeline-container">
                    <div className="timeline-line"></div>
                    {experienceData.map((item, index) => (
                        <ExperienceItem key={index} item={item} index={index} />
                    ))}
                </div>
            </div>
        </section>
    );
};

const ExperienceItem: React.FC<{ item: ExperienceItem; index: number }> = ({ item, index }) => {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    return (
        <motion.div 
            ref={ref}
            className={`experience-timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}
            initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
        >
            <div className="timeline-content group">
                <div className="flex">
                    <div className="w-1/3 hidden md:block">
                        {item.images.length > 0 && (
                            <div className="company-logos flex flex-col gap-4">
                                {item.images.map((img, imgIndex) => (
                                    <motion.img 
                                        key={imgIndex}
                                        src={img}
                                        alt={`Company logo ${imgIndex + 1}`}
                                        className="group-hover:filter-none"
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="w-full md:w-2/3 pl-6">
                        <div className="experience-date">{item.years}</div>
                        <h3 className="text-xl font-bold text-white mb-3">{item.title}</h3>
                        <p className="text-gray-300 mb-4">{item.description}</p>
                        {item.link && (
                            <motion.a 
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                                className="view-company-btn"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {t('experience.viewCompany')}
                            </motion.a>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Experience;